import React from 'react'
import { Head } from '~/components'
import Register from '~/components/Register'

const Curso = () => {
  return (
    <>
      <Head />
      <Register />
    </>
  )
}

export default Curso
