import { Link, navigate } from 'gatsby'
import React, { useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useContext } from '~/hooks'
import cn from 'classnames'

import logoWhite from '~/assets/svg/logo-white.svg'

import * as st from '~/assets/styl/Register.module.styl'

import api, { useApi } from '~/siteApi'
import { graphql, useStaticQuery } from 'gatsby'
import { Oval } from 'react-loader-spinner'

import ReCAPTCHA from 'react-google-recaptcha'

type RecaptchaProps = {
  className?: string
  inputRef: React.MutableRefObject<ReCAPTCHA>
}

export const Recaptcha = ({ inputRef: ref, className }: RecaptchaProps) => (
  <ReCAPTCHA
    ref={ref}
    sitekey="6LedpGkhAAAAAFgfRN52j0L-LTL4ya9BUO3Hp7Jt"
    size="invisible"
    className={className}
  />
)

export default function Register() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [store, setStore] = useState('')
  const [partner, setPartner] = useState(false)
  const role = 2
  //Tratamento de erros
  const [error, setError] = useState(false)
  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmError, setConfirmError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const recaptchaRef = useRef<ReCAPTCHA>()

  const { result: stores } = useApi(
    {
      result: useStaticQuery(graphql`
        query {
          allStores {
            nodes {
              city
              company
              country
              id
              name
              phone
              slug
              zip_code
            }
          }
        }
      `).allStores.nodes,
    },
    'stores'
  )

  const { setToken, setProfile, setRefreshToken } = useContext()
  const setCookie = useCookies()[1]

  function handleRegister(recaptcha: string) {
    setIsLoading(true)
    api('post', 'auth/register', {
      name,
      email,
      password,
      confirm: passwordConfirm,
      role,
      ...(partner ? { store: Number(store) } : {}),
      recaptcha,
    })
      .then(({ token, refresh_token, refresh_token_expiration }) => {
        api('get', 'accounts/profile', {
          headers: { Authorization: `Bearer ${token}` },
        }).then(({ result }) => {
          setToken(token)
          setProfile(result)
          setRefreshToken({
            token: refresh_token,
            expiration: refresh_token_expiration,
          })
          setCookie('token', token, {
            path: '/',
            maxAge: Math.floor(refresh_token_expiration - Date.now() / 1000),
          })
          setIsLoading(false)
          navigate('/')
        })
      })
      .catch(({ errors }) => {
        recaptchaRef.current.reset()
        setIsLoading(false)
        setError(true)
        setNameError(errors?.name || '')
        setEmailError(errors?.email || '')
        setPasswordError(errors?.password || '')
        setConfirmError(errors?.confirm || '')
      })
  }

  return (
    <div className={cn(st.registerContainer, 'page-width')}>
      <div className={st.registerWrapper}>
        <Link className={st.closeRegister} to="/login/">
          <span className="icon-login-arrow"></span>
        </Link>
        <img src={logoWhite} alt="" />
        <section className={st.registerModal}>
          <h1>Cadastro</h1>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              recaptchaRef.current
                .executeAsync()
                .then((value) => handleRegister(value))
            }}
          >
            <label>
              <span className="icon-user-circle"></span>
              <input
                type="text"
                placeholder="Insira seu nome"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
              />
            </label>
            <span className={cn(st.errorMessage, { [st.visible]: error })}>
              {nameError}
            </span>
            <label>
              <span className="icon-user"></span>
              <input
                type="email"
                placeholder="Insira seu e-mail"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </label>
            <span className={cn(st.errorMessage, { [st.visible]: error })}>
              {emailError}
            </span>
            <label>
              <span className="icon-password"></span>
              <input
                type="password"
                placeholder="Insira sua senha"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
            </label>
            <span className={cn(st.errorMessage, { [st.visible]: error })}>
              {passwordError}
            </span>
            <label>
              <span className="icon-password"></span>
              <input
                type="password"
                placeholder="Repita sua senha"
                onChange={(e) => setPasswordConfirm(e.target.value)}
                value={passwordConfirm}
                required
              />
            </label>
            <span className={cn(st.errorMessage, { [st.visible]: error })}>
              {confirmError}
            </span>
            <div className={st.partnerWrapper}>
              <div className={st.setPartner}>
                <input
                  type="checkbox"
                  onChange={(e) => setPartner(e.target.checked)}
                  checked={partner}
                />
                <p>Sou Parceiro Pointer</p>
              </div>
              {partner && (
                <label>
                  <select
                    name="store"
                    id="store"
                    onChange={(e) => setStore(e.target.value)}
                    required
                  >
                    <option value="">Escolha a loja</option>
                    {stores.map((store) => (
                      <option key={store.id} value={store.id}>
                        {store.name}
                      </option>
                    ))}
                  </select>
                </label>
              )}
            </div>
            <Recaptcha inputRef={recaptchaRef} />
            <button type="submit">
              {isLoading ? (
                <Oval
                  height="25"
                  width="25"
                  color="#282b3e"
                  secondaryColor="#CB0103"
                  ariaLabel="loading"
                  wrapperStyle={{ justifyContent: 'center' }}
                />
              ) : (
                'Cadastrar'
              )}
            </button>
          </form>
        </section>
      </div>
    </div>
  )
}
