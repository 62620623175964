// extracted by mini-css-extract-plugin
export var closeRegister = "Register-module--close-register--c82a1";
export var errorMessage = "Register-module--error-message--5cd15";
export var grecaptchaBadge = "Register-module--grecaptcha-badge--3a43c";
export var hideOnDesktop = "Register-module--hide-on-desktop--d7fac";
export var hideOnMobile = "Register-module--hide-on-mobile--2ba2f";
export var iconAccountDefault = "Register-module--icon-account-default--5efd4";
export var iconArrow = "Register-module--icon-arrow--f5d50";
export var iconBrand = "Register-module--icon-brand--afecc";
export var iconBrand1 = "Register-module--icon-brand1--89c7f";
export var iconBrand2 = "Register-module--icon-brand2--8bac5";
export var iconCalculator = "Register-module--icon-calculator--2cad8";
export var iconCamera = "Register-module--icon-camera--abfcc";
export var iconCheckbox = "Register-module--icon-checkbox--2c576";
export var iconChevron = "Register-module--icon-chevron--1272e";
export var iconDelete = "Register-module--icon-delete--761a3";
export var iconDisclaimer = "Register-module--icon-disclaimer--87728";
export var iconDone = "Register-module--icon-done--d4e34";
export var iconDownload = "Register-module--icon-download--3d8dd";
export var iconEdit = "Register-module--icon-edit--197f3";
export var iconFacebook = "Register-module--icon-facebook--0bb7f";
export var iconFaq = "Register-module--icon-faq--ac2d9";
export var iconFaqUp = "Register-module--icon-faq-up--44d60";
export var iconFilledHeart = "Register-module--icon-filled-heart--badff";
export var iconFlag = "Register-module--icon-flag--4c1ce";
export var iconHeart = "Register-module--icon-heart--6519f";
export var iconInstagram = "Register-module--icon-instagram--2bd09";
export var iconLog = "Register-module--icon-log--777c7";
export var iconLoginArrow = "Register-module--icon-login-arrow--ef20a";
export var iconLogout = "Register-module--icon-logout--dd74b";
export var iconPassword = "Register-module--icon-password--a9627";
export var iconPinterestP = "Register-module--icon-pinterest-p--8d56d";
export var iconRate15 = "Register-module--icon-rate-15--64241";
export var iconRate25 = "Register-module--icon-rate-25--cecf5";
export var iconRate35 = "Register-module--icon-rate-35--9aac4";
export var iconRate45 = "Register-module--icon-rate-45--1a343";
export var iconRate55 = "Register-module--icon-rate-55--6fa90";
export var iconRating = "Register-module--icon-rating--98d2c";
export var iconSocial = "Register-module--icon-social--a8a31";
export var iconSocial1 = "Register-module--icon-social1--6a3f9";
export var iconSocial2 = "Register-module--icon-social2--761e4";
export var iconStores = "Register-module--icon-stores--94eb5";
export var iconTrophy = "Register-module--icon-trophy--f75ce";
export var iconUser = "Register-module--icon-user--4c0b6";
export var iconUserCircle = "Register-module--icon-user-circle--31166";
export var iconYoutube = "Register-module--icon-youtube--c98d9";
export var pageWidth = "Register-module--page-width--dc55b";
export var partnerWrapper = "Register-module--partner-wrapper--3e5b2";
export var registerContainer = "Register-module--register-container--66fd4";
export var registerModal = "Register-module--register-modal--8bfd6";
export var registerWrapper = "Register-module--register-wrapper--592fe";
export var setPartner = "Register-module--set-partner--bf701";
export var view = "Register-module--view--8d06c";
export var views = "Register-module--views--00796";
export var visible = "Register-module--visible--e46e3";